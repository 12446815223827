<div class="topbar" [class.set--fixed]="fixed" [class.is--open]="menuActive">
  <div class="container topbar__inner">
    <h1 class="topbar__logo mb-0">
      <a [routerLink]="'/'">
        <img class="topbar__logo__small" src="/assets/gfx/metabolic-emergency-logo.svg" [alt]="'logo-naam' | fromDictionary" />
        <img class="topbar__logo__big" src="/assets/gfx/metabolic-emergency-logo-big.svg" [alt]="'logo-naam' | fromDictionary" />
      </a>
    </h1>

    <div class="topbar__actions">
      <nav class="topbar__nav" [class.is--active]="menuActive">
        <ul class="topbar__nav__list">
          <li class="topbar__nav__list__item">
            <button
              (click)="closeOverlay.emit()"
              [routerLink]="'/'"
              [routerLinkActive]="'is--current'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              Home
            </button>
          </li>
          <li class="topbar__nav__list__item" *ngFor="let page of menuItems">
            <button (click)="closeOverlay.emit()" [routerLink]="page.url" [routerLinkActive]="'is--current'">
              {{ page.menuTitle || page.header.title }}
            </button>
          </li>
          <li>
            <a class="button button--primary" [routerLink]="['/', 'generator']">
              {{ 'generator-menu-titel' | fromDictionary }}
            </a>
          </li>
        </ul>
      </nav>

      <div class="topbar__nav__content">
        <ng-content></ng-content>
      </div>

      <button class="topbar__hamburger d-md-none" (click)="toggleOverlay.emit()" [class.is--active]="menuActive">
        <span>Menu</span>
        <div class="hamburger" [class.is--active]="menuActive">
          <span class="hamburger__lines"></span>
        </div>
      </button>
    </div>
  </div>
</div>
