import { Component, OnInit, Inject, PLATFORM_ID, ChangeDetectionStrategy, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentPage } from '../../models';
import { Store } from '@ngrx/store';
import { ContentState } from '../../store';
import { isPlatformBrowser } from '@angular/common';
import * as selectors from '../../store/selectors/content-pages.selector';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/cookie-ngrx';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPageComponent implements OnInit {
  page$: Observable<ContentPage> | undefined;
  cookiePreferences$?: Observable<CookiePreferences>;

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Object>, private store: Store<ContentState>) {}

  ngOnInit(): void {
    this.page$ = this.store.select(selectors.getSelectedContentPage);
    this.cookiePreferences$ = this.store.select(getCookiePreferences);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
}
