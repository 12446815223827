import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromLandingPages from '../reducers/landing-pages.reducer';
import { LandingPage } from '../../models';
import * as fromRoot from '../../../store';

export const getLandingPageState = createSelector(fromFeature.getLandingState, (state: fromFeature.LandingState) => state.landingPages);

export const getLandingPageEntities = createSelector(getLandingPageState, fromLandingPages.getLandingPagesEntities);

export const getAllLandingPages = createSelector(getLandingPageEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

export const getOrderedLandingPages = createSelector(getAllLandingPages, (pages: LandingPage[]) => {
  const orderedPages = pages.sort((a, b) => a.order - b.order);
  return orderedPages;
});

export const getMenuPages = createSelector(getOrderedLandingPages, (pages: LandingPage[]) => {
  return pages.filter(a => a.inMenu);
});

export const getFooterPages = createSelector(getOrderedLandingPages, (pages: LandingPage[]) => {
  return pages.filter(a => a.inFooter);
});

export const getLandingPagesLoaded = createSelector(getLandingPageState, fromLandingPages.getLandingPagesLoaded);
export const getLandingPagesLoading = createSelector(getLandingPageState, fromLandingPages.getLandingPagesLoading);

export const getSelectedLandingPage = createSelector(getLandingPageEntities, fromRoot.getRouterState, (entities, router): LandingPage => {
  return (
    router.state &&
    entities[router.state.params?.['slug'] || router.state.params?.['id'] || router.state.data?.['slug'] || router.state.data?.['id']]
  );
});

// export const getSelectedLandingPageLoading = createSelector(getLandingPagesLoading, fromRoot.getRouterState, (slugs, router): boolean => {
//   return (
//     router.state &&
//     slugs.includes(router.state.params?.['slug'] || router.state.params?.['id'] || router.state.data?.['slug'] || router.state.data?.['id'])
//   );
// });

// export const getSelectedLandingPageLoaded = createSelector(getLandingPagesLoaded, fromRoot.getRouterState, (slugs, router): boolean => {
//   return (
//     router.state &&
//     slugs.includes(router.state.params?.['slug'] || router.state.params?.['id'] || router.state.data?.['slug'] || router.state.data?.['id'])
//   );
// });
