<article class="content-page topbar-spacer" *ngIf="page$ | async as page">
  <main class="container container-size-sm section-inset-y">
    <h2 class="h2 mb-4" *ngIf="page?.title">{{ page?.title }}</h2>

    <p class="lead text-primary mb-4" *ngIf="page.intro">{{ page.intro }}</p>
    <div
      class="markdown-content"
      [innerHTML]="page?.text || '' | resizeHtmlImages | stripEmbed: (cookiePreferences$ | async) ?? undefined | safe"
    ></div>
  </main>
</article>
