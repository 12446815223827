import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as contentblockActions from '../actions/content-pages.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ContentPagesService } from '../../services';

@Injectable()
export class ContentPagesEffects {
  constructor(private actions$: Actions, private PageService: ContentPagesService) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentblockActions.LoadContentPages),
      switchMap(() =>
        this.PageService.getPages().pipe(
          map(contentPages => contentblockActions.LoadContentPagesSuccess({ contentPages })),
          catchError(error => of(contentblockActions.LoadContentPagesFail({ error })))
        )
      )
    );
  });
}
