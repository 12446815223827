<header
  class="header"
  [class.skew--bottom]="skewBottom"
  [ngClass]="cssClass"
  (fstrInViewport)="inViewChange($event)"
  [once]="false"
  [threshold]="0.25"
>
  <div class="container" [class.container-size-sm]="!imageUrl">
    <main class="row py-5 align-items-center">
      <section class="col-lg-7">
        <h2 class="h2 pb-4" [innerHTML]="title"></h2>
        <p class="text-muted type-body-sm" [innerHTML]="text"></p>
        <ng-container *ngFor="let button of buttons; let i = index">
          <a
            class="button button--primary mt-4 me-4"
            [class.rounded-pill]="i < 2"
            [class.button-link]="i >= 2"
            [routerLink]="button.routerLink"
            *ngIf="button.routerLink?.length"
          >
            <app-icon class="icon-play-circle" anchor="icon-play-circle" *ngIf="i < 2"></app-icon>
            <span>{{ button.text }}</span>
            <app-icon class="icon-chevron-right" anchor="icon-chevron-right" *ngIf="i >= 2"></app-icon>
          </a>
          <a
            [href]="button.url"
            class="button button--primary mt-4 me-4"
            [class.rounded-pill]="i < 2"
            [class.button-link]="i >= 2"
            *ngIf="button.url?.length && !button.routerLink?.length"
          >
            <app-icon class="icon-play-circle" anchor="icon-play-circle" *ngIf="i < 2"></app-icon>
            <span>{{ button.text }}</span>
            <app-icon class="icon-chevron-right" anchor="icon-chevron-right" *ngIf="i >= 2"></app-icon>
          </a>
        </ng-container>
      </section>
      <figure class="col-lg-5 header__crop mt-4 mt-md-0" *ngIf="imageUrl || videoBg">
        <img *ngIf="imageUrl" [src]="imageUrl | resize: 1980" />

        <fstr-html5-video
          class="page-header__video"
          [playerVars]="playerVars"
          *ngIf="inviewPort && videoBg"
          [src]="videoBg"
        ></fstr-html5-video>
      </figure>
    </main>
  </div>
</header>
