import * as fromPages from '../reducers/content-pages.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'content';

export interface ContentState {
  contentPages: fromPages.ContentPagesState;
}

export const reducers: ActionReducerMap<ContentState> = {
  contentPages: fromPages.contentPageReducer,
};

export const getContentState = createFeatureSelector<ContentState>('content');

export const metaReducers: MetaReducer<ContentState>[] = !environment.production ? [] : [];
