import { createReducer, on } from '@ngrx/store';
import { LandingPage } from '../../models';
import * as pageActions from '../actions/landing-pages.action';
import * as utility from '../../../utility';

export interface LandingPagesState {
  entities: { [slug: string]: LandingPage };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: LandingPagesState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const landingPageReducer = createReducer(
  initialState,
  on(pageActions.LoadLandingPages, (state: LandingPagesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadLandingPagesSuccess, (state: LandingPagesState, { landingPages }) => {
    const entities = utility.ToEntities(landingPages, 'slug', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadLandingPagesFail, (state: LandingPagesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
  // on(pageActions.LoadLandingPageBySlug, (state: LandingPagesState, { slug }) => {
  //   slug = slug?.toLowerCase().trim();
  //   return {
  //     ...state,
  //     loading: [...state.loading, slug],
  //   };
  // }),
  // on(pageActions.LoadLandingPageBySlugSuccess, (state: LandingPagesState, { page }) => {
  //   const entities = { ...state.entities, [page.slug.toLowerCase().trim()]: page };
  //   return {
  //     ...state,
  //     entities,
  //     loading: state.loading.filter(slug => slug !== page.slug.toLowerCase().trim()),
  //     loaded: [...state.loaded, page.slug.toLowerCase().trim()],
  //   };
  // }),
  // on(pageActions.LoadLandingPageBySlugFail, (state: LandingPagesState, { error, slug }) => {
  //   slug = slug?.toLowerCase().trim();
  //   return {
  //     ...state,
  //     loading: state.loading.filter(slug => slug !== slug),
  //     loaded: state.loaded.filter(slug => slug !== slug),
  //     error,
  //   };
  // }),
  // //
  // on(pageActions.LoadLandingPageById, (state: LandingPagesState, { id }) => {
  //   return {
  //     ...state,
  //     loading: [...state.loading, id.toString()],
  //   };
  // }),
  // on(pageActions.LoadLandingPageByIdSuccess, (state: LandingPagesState, { page }) => {
  //   const entities = { ...state.entities, [page.id.toString()]: page };
  //   return {
  //     ...state,
  //     entities,
  //     loading: state.loading.filter(id => id !== page.id.toString()),
  //     loaded: [...state.loaded, page.id.toString()],
  //   };
  // }),
  // on(pageActions.LoadLandingPageByIdFail, (state: LandingPagesState, { error, id }) => {
  //   id = id?.toString();
  //   return {
  //     ...state,
  //     loading: state.loading.filter(id => id !== id),
  //     loaded: state.loaded.filter(id => id !== id),
  //     error,
  //   };
  // })
);

export const getLandingPagesEntities = (state: LandingPagesState) => state.entities;
export const getLandingPagesLoading = (state: LandingPagesState) => state.loading;
export const getLandingPagesLoaded = (state: LandingPagesState) => state.loaded;
