<div class="app">
  <div class="app__inner" [class.anim--in]="loading$ | async">
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      [menuActive]="(menuOpen$ | async) ?? false"
      [menuItems]="(menuItems$ | async) ?? []"
      *ngIf="dictionaryLoaded$ | async"
    >
      <app-lang-switcher
        *ngIf="dictionaryLoaded$ | async"
        [languages]="(languages$ | async) ?? []"
        [activeLang]="(activeLanguage$ | async) ?? ''"
      ></app-lang-switcher>
    </app-topbar>

    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <app-footer *ngIf="dictionaryLoaded$ | async" [domain]="domainName" [menuItems]="(footerItems$ | async) ?? []"></app-footer>
  </div>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    *ngIf="dictionaryLoaded$ | async"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonAcceptAll]="'cookie-accepteer-alles-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    [buttonSettings]="'cookie-instellingen-knop-tekst' | fromDictionary"
    [buttonClose]="'cookie-instellingen-sluiten' | fromDictionary"
    [moreInfo]="'cookie-meer-info-tekst' | fromDictionary"
    [typeLabels]="{
      functional: 'cookie-functioneel-label' | fromDictionary,
      analytical: 'cookie-analytics-label' | fromDictionary,
      marketing: 'cookie-marketing-label' | fromDictionary
    }"
    [typeDescriptions]="{
      functional: 'cookie-functioneel-omschrijving' | fromDictionary,
      analytical: 'cookie-analytics-omschrijving' | fromDictionary,
      marketing: 'cookie-marketing-omschrijving' | fromDictionary
    }"
  ></fstr-cookie-notice>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
