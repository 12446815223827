import * as fromLandingpages from '../reducers/landing-pages.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'landing-pages';

export interface LandingState {
  landingPages: fromLandingpages.LandingPagesState;
}

export const reducers: ActionReducerMap<LandingState> = {
  landingPages: fromLandingpages.landingPageReducer,
};

export const getLandingState = createFeatureSelector<LandingState>(featureKey);

export const metaReducers: MetaReducer<LandingState>[] = !environment.production ? [] : [];
