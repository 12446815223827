import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/dictionary-ngrx';
import { ContentPage } from 'src/app/content/models';
import { LandingPage } from 'src/app/landing-page/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() menuItems: LandingPage[] = [];
  @Input() schema: any;
  @Input() socials: [] = [];
  @Input() domain: string = '';
  year = new Date().getFullYear();

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private store: Store, private lang: LanguageService) {}

  ngOnInit(): void {
    this.schema = {
      '@context': 'http://schema.org',
      '@type': this.dict.transform('json-ld-meta-type'),
      'name': this.dict.transform('meta-titel'),
      'url': this.domain,
      'logo': `${this.domain}/assets/gfx/logo-spoorzone.svg`,
      'contactPoint': {
        '@type': 'ContactPoint',
        'email': this.dict.transform('contact-email'),
        'contactType': 'customer service',
      },
      'sameAs': [],
    };

    this.socials.forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema.sameAs.push(socialLink);
    });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
}
