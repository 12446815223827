import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromContent from './store';
import { InViewModule } from '@teamfoster/utility';
import { PipesModule } from '@teamfoster/pipes';
import { Html5VideoPlayerModule, IconModule } from '@teamfoster/ui-components';
import { ContentPageComponent } from './containers/content-page/content-page.component';
import { CookieNgrxModule } from '@teamfoster/cookie-ngrx';

@NgModule({
  declarations: [ContentPageComponent],
  imports: [
    CommonModule,
    ContentRoutingModule,
    StoreModule.forFeature(fromContent.featureKey, fromContent.reducers, {
      metaReducers: fromContent.metaReducers,
    }),
    EffectsModule.forFeature(fromContent.effects),
    InViewModule,
    PipesModule,
    Html5VideoPlayerModule,
    IconModule,
    InViewModule,
    CookieNgrxModule,
  ],
  exports: [],
})
export class ContentModule {}
