import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentPages from '../reducers/content-pages.reducer';
import { ContentPage } from '../../models';
import * as fromRoot from '../../../store';

export const getContentPageState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.contentPages);

export const getContentPageEntities = createSelector(getContentPageState, fromContentPages.getContentPagesEntities);

export const getAllContentPages = createSelector(getContentPageEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedContentPages = createSelector(getAllContentPages, (Pages: ContentPage[]) => {
  const orderedPages = Pages.sort((a, b) => a.order - b.order);
  return orderedPages;
});

// export const getMenuPages = createSelector(getOrderedContentPages, (pages: ContentPage[]) => {
//   return pages.filter(a => a.inMenu);
// });

// export const getFooterPages = createSelector(getOrderedContentPages, (pages: ContentPage[]) => {
//   return pages.filter(a => a.inFooter);
// });

export const getContentPagesLoaded = createSelector(getContentPageState, fromContentPages.getContentPagesLoaded);
export const getContentPagesLoading = createSelector(getContentPageState, fromContentPages.getContentPagesLoading);

export const getSelectedContentPage = createSelector(getContentPageEntities, fromRoot.getRouterState, (entities, router): ContentPage => {
  return router.state && entities[router.state.params?.['pageId'] || router.state.data?.['pageId']];
});
