import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/dictionary-ngrx';
import { NotFoundComponent } from './404.component';
import { ContentPagesGuard } from './content/guards';
import { LandingPagesGuard } from './landing-page/guards/landings-pages.guard';
import { ActiveLanguageGuard, LanguageGuard } from '@teamfoster/language-ngrx';

const APP_GUARDS = [ActiveLanguageGuard, LanguageGuard, DictionaryGuard, LandingPagesGuard, CookieSettingsGuard];

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [...APP_GUARDS],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...APP_GUARDS],
  },
  {
    path: 'generator',
    loadChildren: () => import('./letter/letter.module').then(m => m.LetterModule),
    canActivate: [...APP_GUARDS],
  },
  // {
  //   path: 'generator',
  //   redirectTo: '/offline',
  // },
  {
    path: '💩',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [...APP_GUARDS],
  },
  {
    path: '',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
    canActivate: [...APP_GUARDS],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
