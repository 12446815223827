import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LandingPage } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LandingPagesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getPages() {
    return this.http.get<LandingPage[]>(`${this.BASE_URL}api/landingpages`);
  }

  getPageBySlug(slug: string) {
    return this.http.get<LandingPage>(`${this.BASE_URL}api/landingpage/${slug}`);
  }

  getPageById(id: number) {
    return this.http.get<LandingPage>(`${this.BASE_URL}api/landingpage/${id}`);
  }
}
