import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentPageComponent } from './containers/content-page/content-page.component';
import { ContentPageMetaGuard } from './guards/content-page-meta.guard';

const routes: Routes = [
  {
    path: ':pageId/:target',
    component: ContentPageComponent,
    canActivate: [ContentPageMetaGuard],
    data: {
      logoClass: 'type--content',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}
