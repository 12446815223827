import { createAction, props } from '@ngrx/store';
import { LandingPage } from '../../models';

export const LoadLandingPages = createAction('[Landing] Load Landing Pages');
export const LoadLandingPagesSuccess = createAction('[Landing] Load Landing Pages Success', props<{ landingPages: LandingPage[] }>());
export const LoadLandingPagesFail = createAction('[Landing] Load Landing Pages Fail', props<{ error: any }>());

export const LoadLandingPageBySlug = createAction('[Landing] Load Landing Page by Slug', props<{ slug: string }>());
export const LoadLandingPageBySlugSuccess = createAction('[Landing] Load Landing Page by Slug Success', props<{ page: LandingPage }>());
export const LoadLandingPageBySlugFail = createAction('[Landing] Load Landing Page by Slug Fail', props<{ error: any; slug: string }>());

export const LoadLandingPageById = createAction('[Landing] Load Landing Page by Id', props<{ id: number }>());
export const LoadLandingPageByIdSuccess = createAction('[Landing] Load Landing Page by Id Success', props<{ page: LandingPage }>());
export const LoadLandingPageByIdFail = createAction('[Landing] Load Landing Page by Id Fail', props<{ error: any; id: string }>());
