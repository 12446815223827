import { Component, OnInit, Input, Optional } from '@angular/core';
import { HttpStatusCodeService } from './core/http-status-code.service';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404 section-inset-y">
      <div class="container text-center">
        <h1>{{ 'error-404-title' | fromDictionary }}</h1>
        <p class="text-max-width mx-auto">{{ 'error-404-text' | fromDictionary }}</p>
        <div class="error-404__content text-center mt-4">
          <a [routerLink]="'/'" class="button button--primary">Terug naar home</a>
        </div>
      </div>
    </article>
  `,
})
export class NotFoundComponent {
  constructor(@Optional() private statusCode: HttpStatusCodeService) {
    this.statusCode?.setStatusCode(404);
  }
}
