import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule, MetaReducer, ActionReducerMap } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { DictionaryConfig, DictionaryNgrxModule, DictionaryGuard, LanguageService } from '@teamfoster/dictionary-ngrx';
import { IconModule } from '@teamfoster/ui-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';

import localeNl from './nl';
import { registerLocaleData } from '@angular/common';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotFoundComponent } from './404.component';
import { HttpStatusCodeService } from './core/http-status-code.service';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/cookie-ngrx';
import { LanguageNgrxModule, LanguageInterceptor } from '@teamfoster/language-ngrx';
import { ContentModule } from './content/content.module';
import { DynamicFormConfig, DynamicFormModule } from '@teamfoster/dynamic-form';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

const dictConfig: DictionaryConfig = {
  mapping: [
    {
      domainPart: '.nl/',
      language: 'nl',
    },
  ],
};

const dynamicFormConfig: DynamicFormConfig = {
  genericInputErrorMessage: 'TODO: genericInputErrorMessage',
  genericServerErrorMessage: 'TODO: genericServerErrorMessage',
};

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-74544R797R',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Accept all',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ContentModule,
    IconModule.forRoot({ spritesheetUrl: 'assets/gfx/svg-sprite.svg' }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    DictionaryNgrxModule.forRoot(dictConfig),
    CookieNgrxModule.forRoot(cookieConfig),
    DynamicFormModule.forRoot(dynamicFormConfig),
    BrowserAnimationsModule,
    CoreModule,
    LanguageNgrxModule.forRoot({ defaultLanguage: 'en-US' }),
  ],
  providers: [
    DictionaryGuard,
    LanguageService,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
