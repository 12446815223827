import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as landingblockActions from '../actions/landing-pages.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { LandingPagesService } from '../../services';

@Injectable()
export class LandingPagesEffects {
  constructor(private actions$: Actions, private PageService: LandingPagesService) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(landingblockActions.LoadLandingPages),
      switchMap(() =>
        this.PageService.getPages().pipe(
          map(landingPages => landingblockActions.LoadLandingPagesSuccess({ landingPages })),
          catchError(error => of(landingblockActions.LoadLandingPagesFail({ error })))
        )
      )
    );
  });

  LoadBySlug$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(landingblockActions.LoadLandingPageBySlug),
      switchMap(({ slug }) =>
        this.PageService.getPageBySlug(slug).pipe(
          map(page => landingblockActions.LoadLandingPageBySlugSuccess({ page })),
          catchError(error => of(landingblockActions.LoadLandingPageBySlugFail({ error, slug })))
        )
      )
    );
  });

  LoadById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(landingblockActions.LoadLandingPageById),
      switchMap(({ id }) =>
        this.PageService.getPageById(id).pipe(
          map(page => landingblockActions.LoadLandingPageByIdSuccess({ page })),
          catchError(error => of(landingblockActions.LoadLandingPageByIdFail({ error, id: id.toString() })))
        )
      )
    );
  });
}
